import styled from "styled-components";

interface IInput {
  isPlaceholder?: boolean;
  marginTop?: boolean;
}

const RoundInput = styled.input<IInput>`
  margin-top: ${(props) => (props.marginTop ? "4px" : "0")};
  width: 100%;
  border-radius: 30px;
  padding: 8px 12px;
  font-weight: 400;
  border: 2px solid #a5a6f6;
  color: ${(props) =>
    props.isPlaceholder ? "var(--gray-60)" : "var(--mono-900"};

  &::placeholder {
    color: var(--gray-60);
  }
`;

export default RoundInput;
