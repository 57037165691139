import styled from "styled-components";

type TextElements = Pick<
  JSX.IntrinsicElements,
  "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span"
>;

type IProps<C extends React.ElementType> = {
  children: React.ReactNode;
  as?: keyof TextElements;
} & React.ComponentPropsWithoutRef<C> &
  IElement;

type IElement = {
  fontWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  fontFamily?: "kotori" | "lexend";
  textAlign?: "center" | "left" | "right";
  color?:
    | "poke"
    | "mono-000"
    | "mono-100"
    | "mono-900"
    | "mono-700"
    | "error-900"
    | "gray-70"
    | "iris-700"
    | "cider-flat";
  lineHeight?: number;
  fontSize?:
    | "12px"
    | "13px"
    | "15px"
    | "18px"
    | "20px"
    | "25px"
    | "30px"
    | "32px"
    | "35px"
    | "48px"
    | "64px";
};

export default function Text<C extends React.ElementType = "p">({
  as,
  children,
  fontWeight = 300,
  fontFamily = "lexend",
  textAlign = "left",
  fontSize = "15px",
  color = "mono-900",
  lineHeight = 1.5,
}: IProps<C>) {
  return (
    <Element
      as={as}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
      textAlign={textAlign}
      fontSize={fontSize}
      color={color}
      lineHeight={lineHeight}
    >
      {children}
    </Element>
  );
}

const Element = styled.p<IElement>`
  text-align: ${(props) => props.textAlign};
  font-weight: ${(props) => props.fontWeight};
  font-size: var(--${(props) => props.fontSize});
  color: var(--${(props) => props.color});
  line-height: ${(props) => props.lineHeight};
  font-family: ${(props) =>
    props.fontFamily === "lexend" ? "var(--lexend)" : "var(--kotori)"};
`;
