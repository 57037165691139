export default function HamburgerMenu() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5002 7.55566H5.8335C5.55735 7.55566 5.3335 7.77952 5.3335 8.05566C5.3335 8.33181 5.55735 8.55566 5.8335 8.55566H13.5002C13.7763 8.55566 14.0002 8.33181 14.0002 8.05566C14.0002 7.77952 13.7763 7.55566 13.5002 7.55566Z"
        fill="#82828E"
      />
      <path
        d="M13.5 12.1111H2.5C2.22386 12.1111 2 12.3349 2 12.6111C2 12.8872 2.22386 13.1111 2.5 13.1111H13.5C13.7761 13.1111 14 12.8872 14 12.6111C14 12.3349 13.7761 12.1111 13.5 12.1111Z"
        fill="#82828E"
      />
      <path
        d="M13.5 3H2.5C2.22386 3 2 3.22386 2 3.5C2 3.77614 2.22386 4 2.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3Z"
        fill="#82828E"
      />
    </svg>
  );
}
