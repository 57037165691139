interface IProps {
  fill?: string;
}

export default function Crossmark({ fill = "white" }: IProps) {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 1.1125L6.8875 0.5L4 3.3875L1.1125 0.5L0.5 1.1125L3.3875 4L0.5 6.8875L1.1125 7.5L4 4.6125L6.8875 7.5L7.5 6.8875L4.6125 4L7.5 1.1125Z"
        fill={fill}
      />
    </svg>
  );
}
