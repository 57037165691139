import Link from "next/link";
import styled from "styled-components";
import { useState } from "react";
import { useRouter } from "next/router";
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import { useMediaQuery } from "react-responsive";

import Toast from "@/common/Toast/Toast";
import Pencil, { Path as PencilPath } from "@/common/Icons/Pencil";
import HamburgerMenu from "@/common/Icons/HamburgerMenu";
import { useAppContext } from "@/state/Context";
import PillOutline from "@/common/PillOutline/PillOutline";
import Spacer from "@/common/Spacer/Spacer";
import { PillButton } from "@/common/Buttons/Buttons";
import ConnectWalletAndEmailModal from "../ConnectWalletAndEmailModal/ConnectWalletAndEmailModal";
import Copy, { Path as CopyPath } from "@/common/Icons/Copy";

export default function Header({
  walletAddress: walletAddressProps,
}: {
  walletAddress: string;
}) {
  const {
    walletAddress: walletAddressState,
    setWalletAddress,
    setPageLoaderStatus,
  } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);

  const walletAddress = walletAddressState || walletAddressProps;

  const isMobile = useMediaQuery({ query: "(max-width:  700px)" });

  const router = useRouter();
  const path = router.asPath;

  const truncatedWalletAddress = walletAddress
    ? `${walletAddress.slice(0, 6)}...${walletAddress.slice(38)}`
    : "";

  const signout = async () => {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/auth/disconnect`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    if (res.ok) {
      setWalletAddress("");
      router.push("/");
    }
  };

  const changeRoute = (route: string, path: string) => {
    if (path === route) return;
    setPageLoaderStatus(10);
    router.push(route);
  };

  const updatePageLoader = (isActive: boolean) => {
    if (isActive) {
      setPageLoaderStatus(10);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(walletAddress);
    setIsToastOpen(true);
  };

  return (
    <Container>
      <ContainerFixed>
        <LogoContainer>
          <Link href="/" passHref>
            <Logo
              onClick={() => updatePageLoader(path !== "/")}
              isSelected={path === "/"}
            >
              POKE
            </Logo>
          </Link>
        </LogoContainer>
        <MiddleLinks>
          {!isMobile ? (
            <>
              <Link href={"/"} passHref>
                <LinkItem
                  onClick={() =>
                    updatePageLoader(
                      !path.includes("/profile") &&
                        !path.includes("/settings") &&
                        !path.includes("/settings")
                    )
                  }
                  isSelected={
                    !path.includes("/profile") &&
                    !path.includes("/settings") &&
                    !path.includes("/blog")
                  }
                >
                  Interact
                </LinkItem>
              </Link>
            </>
          ) : null}
          {walletAddress.length !== 0 && !isMobile ? (
            <>
              <Spacer size={32} axis="x" />
              <Link href={`/profile/${walletAddress}`} passHref>
                <LinkItem
                  onClick={() =>
                    updatePageLoader(path !== `/profile/${walletAddress}`)
                  }
                  isSelected={path.includes("/profile")}
                >
                  Profile
                </LinkItem>
              </Link>
            </>
          ) : null}
          {!isMobile ? (
            <>
              <Spacer size={32} axis="x" />
              <Link href={"/blog"} passHref>
                <LinkItem
                  onClick={() => updatePageLoader(path !== "/blog")}
                  isSelected={path.includes("/blog")}
                >
                  Blog
                </LinkItem>
              </Link>
            </>
          ) : null}
        </MiddleLinks>
        <RightSideContainer>
          {walletAddress ? (
            <>
              <PillOutline>{truncatedWalletAddress}</PillOutline>
              <Spacer size={24} axis="x" />
              <Menu>
                <StyledMenuButton
                  aria-label="hamburger menu"
                  name="hamburger menu"
                >
                  <HamburgerMenu />
                </StyledMenuButton>
                <StyledMenuList>
                  <StyledMenuItem
                    onSelect={() => changeRoute("/settings", path)}
                  >
                    Profile Settings
                    <Pencil />
                  </StyledMenuItem>
                  <StyledMenuItem onSelect={copyToClipboard}>
                    {truncatedWalletAddress}
                    <Copy fill={"var(--iris-700)"} />
                  </StyledMenuItem>
                  {isMobile ? (
                    <>
                      <StyledMenuItem onSelect={() => changeRoute("/", path)}>
                        Interact
                      </StyledMenuItem>
                      <StyledMenuItem
                        onSelect={() =>
                          changeRoute(`/profile/${walletAddress}`, path)
                        }
                      >
                        Profile
                      </StyledMenuItem>
                      <StyledMenuItem
                        onSelect={() => changeRoute("/blog", path)}
                      >
                        Blog
                      </StyledMenuItem>
                    </>
                  ) : null}
                  <StyledMenuItem
                    onSelect={() => changeRoute("/archive", path)}
                  >
                    Archive
                  </StyledMenuItem>
                  <StyledMenuItem onSelect={signout}>Logout</StyledMenuItem>
                </StyledMenuList>
              </Menu>
            </>
          ) : (
            <PillButton onClick={() => setIsOpen(true)}>Connect</PillButton>
          )}
        </RightSideContainer>
        <ConnectWalletAndEmailModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </ContainerFixed>
      <Toast isOpen={isToastOpen} setIsOpen={setIsToastOpen}>
        Wallet address copied!
      </Toast>
    </Container>
  );
}

const Container = styled.nav`
  height: 70px;
`;

const ContainerFixed = styled.nav`
  height: 70px;
  padding: 0 32px;
  background-color: var(--background-solid);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: fixed;
  width: 100%;
  z-index: 10;
`;

const MiddleLinks = styled.div`
  display: flex;
`;

const LogoContainer = styled.div`
  width: 260px;

  @media (max-width: 900px) {
    width: auto;
  }
`;

interface ILogo {
  isSelected: boolean;
}

const Logo = styled.a<ILogo>`
  color: var(--poke);
  font-size: var(--32px);
  font-family: var(--kotori);
  font-weight: 700;
  transition: color 150ms ease-in-out;
  cursor: ${(props) => (props.isSelected ? "default" : "pointer")};

  &:hover {
    color: ${(props) => (props.isSelected ? "var(--poke)" : "var(--iris-800)")};
  }
`;

const RightSideContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 260px;

  @media (max-width: 900px) {
    width: auto;
  }
`;

interface ILinkItem {
  isSelected: boolean;
}

const LinkItem = styled.a<ILinkItem>`
  color: var(--poke);
  cursor: ${(props) => (props.isSelected ? "default" : "pointer")};
  text-transform: capitalize;
  font-weight: ${(props) => (props.isSelected ? "700" : "400")};
  font-size: var(--18px);
  font-family: var(--kotori);
  transition: color 100ms ease-in-out;

  &:hover {
    color: ${(props) => (props.isSelected ? "var(--poke)" : "var(--iris-700)")};
  }
`;

const StyledMenuButton = styled(MenuButton)`
  background-color: transparent;
`;

const StyledMenuList = styled(MenuList)`
  border-radius: 8px;
  width: 240px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 8px;
  margin-top: 8px;
`;

const StyledMenuItem = styled(MenuItem)`
  border-radius: 4px;
  font-size: var(--15px);
  color: var(--mono-700);
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &[data-reach-menu-item][data-selected] {
    background-color: var(--poke);
    background-color: var(--iris-100);
    color: var(--mono-900);
    ${PencilPath} {
      fill: var(--poke);
    }
    ${CopyPath} {
      fill: var(--poke);
    }
  }
`;
