import styled from "styled-components";

import CheckWithCircle from "../Icons/CheckWithCircle";
import RoundInput from "@/common/Inputs/RoundInput";

interface IProps {
  text: string;
}

export default function ReadOnlyInputWithCheck({ text }: IProps) {
  return (
    <Container>
      <Input readOnly value={text} />
      <IconContainer>
        <CheckWithCircle />
      </IconContainer>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled(RoundInput)`
  width: 100%;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translate(0, -50%) scale(1.3);
`;
