import styled from "styled-components";

export default function Copy({ fill = "var(--iris-700)" }: { fill?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      // fill="#5d5fef"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M16 7V16H7V7H16ZM16 6H7C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7V16C6 16.2652 6.10536 16.5196 6.29289 16.7071C6.48043 16.8946 6.73478 17 7 17H16C16.2652 17 16.5196 16.8946 16.7071 16.7071C16.8946 16.5196 17 16.2652 17 16V7C17 6.73478 16.8946 6.48043 16.7071 6.29289C16.5196 6.10536 16.2652 6 16 6Z"
        fill={fill}
      />
      <Path
        d="M4 11H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H11V4H4V11Z"
        fill={fill}
      />
    </svg>
  );
}

export const Path = styled.path<{ fill: string }>`
  transition: fill 100ms ease-in-out;
  fill: ${(props) => props.fill};
  // fill: #9092ff;
`;
