import styled from "styled-components";

interface IProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  children: React.ReactNode;
  isRequired?: boolean;
  color?: string;
  allowWrap?: boolean;
  center?: boolean;
}

export default function Label({
  children,
  isRequired,
  color = "var(--mono-900)",
  allowWrap = false,
  center = false,
  ...props
}: IProps) {
  return (
    <StyledLabel color={color} allowWrap={allowWrap} center={center} {...props}>
      {children}
      {isRequired ? "*" : ""}
    </StyledLabel>
  );
}

interface IStyledLabel {
  color: string;
  allowWrap: boolean;
  center: boolean;
}

const StyledLabel = styled.label<IStyledLabel>`
  font-size: var(--13px);
  color: ${(props) => props.color};
  white-space: ${(props) => (props.allowWrap ? "nowrap" : "")};
  text-align: ${(props) => (props.center ? "center" : "left")};
  font-weight: 500;
  margin-left: 8px;
`;
