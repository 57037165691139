import styled from "styled-components";
import Spacer from "@/common/Spacer/Spacer";
import { useState } from "react";
import * as Sentry from "@sentry/react";

import { useAppContext } from "@/state/Context";
import { connectToWallet } from "@/utils/connectToWallet";
import { PillButton } from "@/common/Buttons/Buttons";

interface IProps {
  setIsFirstLogin: (value: boolean) => void;
  closeModal: () => void;
}

export default function ConnectWallet({ setIsFirstLogin, closeModal }: IProps) {
  const [isMetaMaskWindowOpen, setIsMetaMaskWindowOpen] = useState(false);
  const { setWalletAddress } = useAppContext();

  const connectWallet = async () => {
    setIsMetaMaskWindowOpen(true);
    try {
      const walletAddress = await connectToWallet({});

      const loginRes = await fetch(
        `${process.env.NEXT_PUBLIC_BACKEND_URL}/auth/login-status?wallet=${walletAddress}`
      );

      const { initialLogin } = await loginRes.json();

      if (initialLogin) {
        setIsFirstLogin(true);
        fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}/auth/login-status`, {
          method: "POST",
          body: JSON.stringify({
            walletAddress,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
      } else {
        closeModal();
      }

      if (walletAddress) {
        setWalletAddress(walletAddress);
      }
      setIsMetaMaskWindowOpen(false);
    } catch (err) {
      setIsMetaMaskWindowOpen(false);
      Sentry.captureException(err);
    }
  };

  return (
    <ConnectWalletContainer>
      <Spacer size={16} axis="y" />
      <PillButton
        size="large"
        onClick={connectWallet}
        disabled={isMetaMaskWindowOpen}
      >
        <Text>Connect with</Text>
        <Spacer size={4} axis="x" /> <MetaMaskText>MetaMask</MetaMaskText>
        <Spacer size={44} axis="x" />
        <MetaMaskBackground>
          <MetaMaskLogo src="/metamask-fox.svg" alt="metamask logo" />
        </MetaMaskBackground>
      </PillButton>
    </ConnectWalletContainer>
  );
}

const ConnectWalletContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;

  @media (max-width: var(--small)) {
    margin-top: 32px;
  }
`;

const MetaMaskBackground = styled.div`
  position: relative;
  height: 32px;
  width: 32px;
  background-color: white;
  border-radius: 50%;
`;

const MetaMaskLogo = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

const Text = styled.p`
  font-weight: 300;
`;

const MetaMaskText = styled.span`
  font-weight: 600;
`;
