import { css } from "styled-components";

export const BaseButton = css`
  background-color: var(--poke);
  color: white;
  border-radius: 40px;
  padding: 10px 24px;
  cursor: pointer;
  border: none;
  position: relative;
`;

interface BaseButtonWithTransition {
  disabled?: boolean;
}

export const BaseButtonWithTransition = css<BaseButtonWithTransition>`
  transition: all 215ms ease-in-out;
  ${BaseButton}
  &:hover {
    background-color: ${(props) =>
      props.disabled ? "var(--iris-600)" : "var(--iris-800)"};
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    background: linear-gradient(
      266.51deg,
      #5d5fef 22.45%,
      #8079f1 59.76%,
      #f0cdf8 96.67%
    );
    transition: opacity 0.2s ease-out;
    z-index: 1;
    opacity: 0;
  }
  &:active:after {
    opacity: ${(props) => (props.disabled ? 0 : 1)};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--iris-600);
  }
`;
