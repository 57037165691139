export default function CheckWithCircle() {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 0.5C8.4233 0.5 6.39323 1.11581 4.66652 2.26957C2.9398 3.42332 1.59399 5.0632 0.79927 6.98182C0.0045495 8.90045 -0.203386 11.0116 0.201759 13.0484C0.606904 15.0852 1.60693 16.9562 3.07538 18.4246C4.54383 19.8931 6.41476 20.8931 8.45156 21.2982C10.4884 21.7034 12.5996 21.4955 14.5182 20.7007C16.4368 19.906 18.0767 18.5602 19.2304 16.8335C20.3842 15.1068 21 13.0767 21 11C21 8.21523 19.8938 5.54451 17.9246 3.57538C15.9555 1.60625 13.2848 0.5 10.5 0.5ZM9 15.1931L5.25 11.4431L6.44296 10.25L9 12.8069L14.5575 7.25L15.7543 8.43943L9 15.1931Z"
        fill="#5D5FEF"
      />
    </svg>
  );
}
