import * as RadixToast from "@radix-ui/react-toast";
import styled from "styled-components";

interface IProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  children: React.ReactNode;
}

export default function Toast({ isOpen, setIsOpen, children }: IProps) {
  return (
    <>
      <StyledToast open={isOpen} onOpenChange={setIsOpen}>
        {children}
      </StyledToast>
      <StyledViewport />
    </>
  );
}

const StyledToast = styled(RadixToast.Root)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--poke);
  padding: 16px 24px;
  margin: 20px;
  border-radius: 10px;
  color: var(--mono-100);
`;

const StyledViewport = styled(RadixToast.Viewport)`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 100;
  outline: none;
`;
