import styled from "styled-components";
import { BaseButton } from "@/common/Buttons/shared";

interface IProps {
  children: React.ReactNode;
}

export default function PillOutline({ children }: IProps) {
  return (
    <Container>
      <Span>{children}</Span>
    </Container>
  );
}

export const Container = styled.div`
  ${BaseButton}
  border-radius: 30px;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid var(--gray-90);
  background-color: transparent;
  cursor: default;
  > * {
    position: relative;
    z-index: 3;
  }
`;

const Span = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mono-900);
  white-space: nowrap;
`;
