import React, { useState, useEffect } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import useMeasure from "react-use-measure";
import { ResizeObserver } from "@juggle/resize-observer";

import { useAppContext } from "@/state/Context";
import Spacer from "@/common/Spacer/Spacer";
import ConnectWallet from "@/components/ConnectWallet/ConnectWallet";
import { SquareLink } from "@/common/Links/Links";
import Crossmark from "@/common/Icons/Crossmark";
import LinkEmail from "./LinkEmail";

interface IProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  linkEmailOnly?: boolean;
}

export default function ConnectWalletAndEmailModal({
  isOpen,
  setIsOpen,
  linkEmailOnly = false,
}: IProps) {
  const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const { walletAddress } = useAppContext();
  const [ref, { height }] = useMeasure({ polyfill: ResizeObserver });

  const close = () => setIsOpen(false);

  useEffect(() => {
    setIsMetaMaskInstalled(typeof window.ethereum !== "undefined");
  }, []);

  return (
    <Dialog.Root open={true}>
      <AnimatePresence>
        {isOpen && (
          <Dialog.Portal>
            <MotionOverlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { delay: 0.15, duration: 0.15 } }}
            >
              <MotionContent
                height={height}
                onEscapeKeyDown={close}
                onPointerDownOutside={close}
                initial={{ opacity: 0, scale: 0.96 }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    opacity: { delay: 0.25 },
                    scale: { delay: 0.25 },
                    height: { delay: 0, duration: 0.4 },
                  },
                  height: height + 128 || "auto",
                }}
                exit={{
                  opacity: 0,
                  scale: 0.96,
                  transition: { duration: 0.15 },
                }}
              >
                <div ref={ref}>
                  <CloseModal
                    initial={{ scale: 2.5 }}
                    whileHover={{ scale: 2.8 }}
                    onClick={close}
                  >
                    <Crossmark fill="var(--iris-600)" />
                  </CloseModal>
                  <DesktopModal>
                    {isMetaMaskInstalled ? (
                      <AnimatePresence exitBeforeEnter>
                        {walletAddress && (isFirstLogin || linkEmailOnly) ? (
                          <motion.div
                            key={1}
                            initial={{ opacity: 0, x: 200 }}
                            animate={{
                              opacity: 1,
                              x: 0,
                              transition: { type: "tween" },
                            }}
                          >
                            <LinkEmail
                              linkEmailOnly={linkEmailOnly}
                              setIsOpen={setIsOpen}
                            />
                          </motion.div>
                        ) : (
                          <motion.div
                            key={2}
                            exit={{
                              opacity: 0,
                              x: -200,
                              transition: { duration: 0.2 },
                            }}
                          >
                            <Header>Connect a Wallet</Header>
                            <ConnectWallet
                              closeModal={close}
                              setIsFirstLogin={setIsFirstLogin}
                            />
                          </motion.div>
                        )}
                      </AnimatePresence>
                    ) : (
                      <>
                        <Title>Looks like you don&#39;t have MetaMask</Title>
                        <Spacer size={16} axis="y" />
                        <BodyText>
                          POKE uses MetaMask to connect you to the Web3 world
                        </BodyText>
                        <Spacer size={32} axis="y" />
                        <ButtonContainer>
                          <Close onClick={close}>Close</Close>
                          <Spacer size={24} axis="x" />
                          <SquareLink href="https://metamask.io/">
                            Get MetaMask
                          </SquareLink>
                        </ButtonContainer>
                      </>
                    )}
                  </DesktopModal>
                  <MobileModal>
                    <Title>Looks like you are on a mobile device</Title>
                    <Spacer size={16} axis="y" />
                    <p>
                      POKE currently only supports desktop wallet integration.
                    </p>
                    <Spacer size={16} axis="y" />
                    <p>Please try again on a desktop computer.</p>
                    <Spacer size={32} axis="y" />
                    <ButtonContainer>
                      <Close onClick={close}>Close</Close>
                    </ButtonContainer>
                  </MobileModal>
                </div>
              </MotionContent>
            </MotionOverlay>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  );
}

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  height: 100%;
`;

const Content = styled(Dialog.Content)`
  min-width: 300px;
  background: white;
  padding: 64px 64px 48px 64px;
  border-radius: 8px;
  background-color: var(--mono-100);
  width: 636px;
  min-height: 300px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 700px) {
    width: 90vw;
  }
`;

const MotionOverlay = motion(Overlay);
const MotionContent = motion(Content);

const Header = styled.p`
  font-size: var(--25px);
  font-family: var(--kotori);
  text-align: center;
  font-weight: 700;
`;

const CloseModal = styled(motion.button)`
  position: absolute;
  top: 25px;
  right: 25px;
`;

const DesktopModal = styled.div`
  display: block;

  @media (hover: none) {
    display: none;
  }
`;

const MobileModal = styled.div`
  display: none;

  @media (hover: none) {
    display: block;
  }
`;

const Title = styled.p`
  font-size: var(--20px);
  font-family: var(--kotori);
  color: var(--poke);
  font-weight: 700;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const Close = styled.button`
  background-color: transparent;
  cursor: pointer;
  color: var(--gray-70);
  transition: color 100ms ease;
  &:hover {
    color: black;
  }
`;

const BodyText = styled.p`
  font-weight: 300;
`;
