import styled from "styled-components";
import { BaseButtonWithTransition } from "@/common/Buttons/shared";
import Text from "@/common/Text/Text";

interface IProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler;
  width?: "full";
  href: string;
  openInNewTab?: boolean;
}

export default function SquareLink({
  children,
  onClick,
  width,
  href,
  openInNewTab = true,
}: IProps) {
  return (
    <Link
      href={href}
      target={openInNewTab ? "_blank" : ""}
      rel={openInNewTab ? "noopener noreferrer" : ""}
      onClick={onClick}
      width={width}
    >
      <Text fontWeight={400} color="mono-000" textAlign="center">
        {children}
      </Text>
    </Link>
  );
}

interface ISquareButton {
  width?: "full";
}

export const Link = styled.a<ISquareButton>`
  ${BaseButtonWithTransition}
  color: white;
  padding: 10px 24px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  width: ${(props) => (props.width === "full" ? "100%" : "auto")};
  &::after {
    border-radius: 8px;
  }
  > * {
    position: relative;
    z-index: 3;
  }
`;
