import styled from "styled-components";
import { BaseButtonWithTransition } from "@/common/Buttons/shared";

interface IProps extends React.HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  width?: "full";
  type?: "submit" | "button";
  disabled?: boolean;
}

export default function SquareButtonOutline({
  children,
  width,
  type,
  disabled = false,
  ...rest
}: IProps) {
  return (
    <Button
      {...rest}
      onClick={rest.onClick}
      width={width}
      type={type}
      disabled={disabled}
    >
      <Span>{children}</Span>
    </Button>
  );
}

interface ISquareButtonOutline {
  width?: "full";
}

export const Button = styled.button<ISquareButtonOutline>`
  ${BaseButtonWithTransition}
  color: var(--poke);
  background-color: transparent;
  padding: 10px 24px;
  cursor: pointer;
  border: 1px solid var(--poke);
  border-radius: 8px;
  width: ${(props) => (props.width === "full" ? "100%" : "auto")};

  &:hover {
    color: white;
    background-color: var(--poke);
  }

  &::after {
    border-radius: 8px;
  }

  > * {
    position: relative;
    z-index: 3;
  }

  &:disabled {
    opacity: 0.7;
    color: var(--poke);
    background-color: var(--mono-300);
  }
`;

const Span = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;
