import styled from "styled-components";
import { Fragment } from "react";
import Spacer from "@/common/Spacer/Spacer";

interface IProps {
  text: string | undefined;
  spacing?: number;
  centered?: boolean;
}

export default function ErrorText({
  text,
  spacing = 4,
  centered = false,
}: IProps) {
  if (!text) {
    return null;
  }

  return (
    <Fragment>
      <Spacer size={spacing} axis="y" />
      <Text centered={centered} role="alert">
        {text}
      </Text>
      <Spacer size={spacing} axis="y" />
    </Fragment>
  );
}

const Text = styled.p<{ centered: boolean }>`
  ${(props) => (props.centered ? "text-align: center" : "")};
  color: var(--error-900);
  font-weight: 400;
`;
