import styled from "styled-components";

export default function Pencil() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M12.0375 3.9375C12.4875 3.4875 12.4875 2.8125 12.0375 2.3625L10.0125 0.3375C9.5625 -0.1125 8.8875 -0.1125 8.4375 0.3375L0 8.775V12.375H3.6L12.0375 3.9375ZM9.225 1.125L11.25 3.15L9.5625 4.8375L7.5375 2.8125L9.225 1.125ZM1.125 11.25V9.225L6.75 3.6L8.775 5.625L3.15 11.25H1.125Z"
        // fill="#5D5FEF"
      />
    </svg>
  );
}

export const Path = styled.path`
  transition: fill 100ms ease-in-out;
  fill: var(--iris-700);
`;
