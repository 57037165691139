import styled from "styled-components";
import { BaseButtonWithTransition } from "@/common/Buttons/shared";
import Text from "@/common/Text/Text";
import NextLink from "next/link";

interface IProps extends React.HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  width?: "full";
  type?: "submit" | "button";
  href: string;
  openInNewTab?: boolean;
}

export default function SquareOutlineLink({
  children,
  width,
  type,
  href,
  openInNewTab = true,
  ...rest
}: IProps) {
  return (
    <NextLink href={href} passHref>
      <Link
        href={href}
        target={openInNewTab ? "_blank" : ""}
        rel={openInNewTab ? "noopener noreferrer" : ""}
        width={width}
      >
        <Span>{children}</Span>
      </Link>
    </NextLink>
  );
}

interface ISquareLinkOutline {
  width?: "full";
}

export const Link = styled.a<ISquareLinkOutline>`
  ${BaseButtonWithTransition}
  color: var(--poke);
  background-color: transparent;
  padding: 8px 24px;
  border: 2px solid var(--poke);
  cursor: pointer;
  border-radius: 8px;
  width: ${(props) => (props.width === "full" ? "100%" : "auto")};
  &:hover {
    color: white;
    background-color: var(--poke);
  }
  &::after {
    border-radius: 8px;
  }
  > * {
    position: relative;
    z-index: 3;
  }

  display: block;
`;

const Span = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;
