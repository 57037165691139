import styled from "styled-components";
import { BaseButtonWithTransition } from "@/common/Buttons/shared";

type IProps<C extends React.ElementType> = {
  as?: C | keyof JSX.IntrinsicElements;
  children: React.ReactNode;
  width?: "full";
  size?: "small" | "large";
} & React.ComponentPropsWithoutRef<C>;

export default function PillButton<C extends React.ElementType = "button">({
  children,
  width,
  as,
  size = "small",
  ...props
}: IProps<C>) {
  return (
    <Button as={as} width={width} size={size} {...props}>
      <Span>{children}</Span>
    </Button>
  );
}

interface IPillButton {
  width?: "full";
  size: "small" | "large";
}

export const Button = styled.button<IPillButton>`
  ${BaseButtonWithTransition}
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--poke);
  padding: ${(props) => (props.size === "large" ? "16px" : "10px 24px;")};
  border-radius: 60px;
  font-weight: 400;
  width: ${(props) => (props.width === "full" ? "100%" : "auto")};
  > * {
    position: relative;
    z-index: 3;
  }
`;

const Span = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;
