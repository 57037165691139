import { SupabaseClient as _SupabaseClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.NEXT_PUBLIC_SB_PUBLIC_URL as string;
const supabaseKey = process.env.NEXT_PUBLIC_SB_ANON_KEY as string;

export class SupabaseClient extends _SupabaseClient {
  constructor() {
    super(supabaseUrl, supabaseKey);
  }
}
