import styled from "styled-components";
import { forwardRef } from "react";
import { BaseButtonWithTransition } from "@/common/Buttons/shared";

interface IProps {
  children: React.ReactNode;
  width?: "full";
  href: string;
  onClick?: React.MouseEventHandler;
  size?: "small" | "large";
}

const PillLink = forwardRef<HTMLAnchorElement, IProps>(
  ({ children, width, href, onClick, size = "small" }, ref) => {
    return (
      <Button onClick={onClick} width={width} size={size} ref={ref} href={href}>
        <Span>{children}</Span>
      </Button>
    );
  }
);

export default PillLink;

interface IPillButtonLarge {
  width?: "full";
  size: "small" | "large";
}

export const Button = styled.a<IPillButtonLarge>`
  ${BaseButtonWithTransition}
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--poke);
  padding: ${(props) => (props.size === "large" ? "16px" : "10px 24px")};
  border-radius: 60px;
  font-weight: 400;
  width: ${(props) => (props.width === "full" ? "100%" : "auto")};
  > * {
    position: relative;
    z-index: 3;
  }
`;

const Span = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;
