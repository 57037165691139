import styled from "styled-components";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { PillButton } from "@/common/Buttons/Buttons";
import RoundInput from "@/common/Inputs/RoundInput";
import Label from "@/common/Label/Label";
import Spacer from "@/common/Spacer/Spacer";
import ErrorText from "@/common/ErrorText/ErrorText";
import { useAppContext } from "@/state/Context";
import ReadOnlyInputWithCheck from "@/common/ReadOnlyInputWithCheck/ReadOnlyInputWithCheck";

interface IProps {
  setIsOpen: (value: boolean) => void;
  linkEmailOnly: boolean;
  setEmail?: (email: string) => void;
}

interface Inputs {
  email: string;
  errorMessage: string;
}

export default function LinkEmail({
  setIsOpen,
  linkEmailOnly,
  setEmail,
}: IProps) {
  const { walletAddress } = useAppContext();

  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm<Inputs>();

  const sendEmail: SubmitHandler<Inputs> = (data, e) => {
    e?.preventDefault();
    try {
      setEmail?.(data.email);
      fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}/auth/email/verify`, {
        method: "POST",
        body: JSON.stringify({
          email: data.email,
          walletAddress,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      setIsEmailSubmitted(true);
    } catch (err) {
      setError("errorMessage", {
        type: "custom",
        message: "Something went wrong. Try again.",
      });
      console.log(err);
    }
  };

  const truncatedAddress = `${walletAddress.slice(
    0,
    6
  )}...${walletAddress.slice(38)}`;

  return (
    <Container>
      <Title>Link Your Wallet to an Email</Title>
      {isEmailSubmitted ? (
        <>
          <Spacer size={32} axis="y" />
          <BodyText>
            A confirmation email has been sent to {getValues("email")}
          </BodyText>
          <Spacer size={32} axis="y" />
          <ResendLink onClick={() => setIsEmailSubmitted(false)}>
            Didn&apos;t receive one? Send again.
          </ResendLink>
          <Spacer size={32} axis="y" />
          <PillButton onClick={() => setIsOpen(false)} width="full">
            Close
          </PillButton>
        </>
      ) : (
        <>
          {linkEmailOnly ? null : (
            <>
              <Spacer size={32} axis="y" />
              <BodyText>
                Link your email for more claiming options, and receive important
                POKE updates for Claiming, Feature Updates, and more!
              </BodyText>
            </>
          )}
          <Spacer size={32} axis="y" />
          <InputContainer onSubmit={handleSubmit(sendEmail)}>
            <Label style={{ alignSelf: "flex-start" }}>
              Your Wallet Address
            </Label>
            <Spacer size={4} axis="y" />
            <ReadOnlyInputWithCheck text={truncatedAddress} />
            <Spacer size={32} axis="y" />
            <Label style={{ alignSelf: "flex-start" }} htmlFor="email">
              Email
            </Label>
            <Spacer size={4} axis="y" />
            <RoundInput
              {...register("email", {
                required: true,
                pattern: {
                  value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  message: "Email must be valid",
                },
              })}
              id="email"
              type="email"
            />
            {errors.email?.message || errors.errorMessage?.message ? (
              <>
                <Spacer size={8} axis="y" />
                <ErrorText
                  text={errors.email?.message || errors.errorMessage?.message}
                />
              </>
            ) : null}
            <Spacer size={16} axis="y" />
            <PillButton type="submit" width="full">
              Link Email to Wallet
            </PillButton>
            {linkEmailOnly ? null : (
              <>
                <Spacer size={24} axis="y" />
                <Skip onClick={() => setIsOpen(false)} type="button">
                  Skip
                </Skip>
              </>
            )}
          </InputContainer>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 284px;
  width: 100%;
`;

const Title = styled.p`
  font-size: var(--25px);
  font-family: var(--kotori);
  text-align: center;
  font-weight: 700;
`;

const BodyText = styled.p`
  color: var(--mono-700);
  text-align: center;
`;

const Skip = styled.button`
  font-weight: 300;
  color: var(--mono-700);
  transition: color 100ms ease-in-out;
  &:hover {
    color: var(--mono-900);
  }
`;

const ResendLink = styled.button`
  text-align: center;
  color: var(--iris-800);
  margin: 0 auto;
  display: block;
  transition: 100ms ease-in-out;
  &:hover {
    color: var(--poke);
  }
`;
