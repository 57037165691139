import { ethers } from "ethers";
import { SupabaseClient } from "./supabase/SupabaseClient";

const supabase = new SupabaseClient();

const createMessage = (walletAddress: string, nonce: string) => {
  const messageContent = [
    "Welcome to POKE, the Proof of Knowledge and Experience App!\n",
    "This request will connect your wallet to the POKE App and create an account if necessary. It will not create a blockchain transaction or cost any gas fees.\n",
    "Your authentication status will reset in 72 hours.\n",
    "Wallet address:",
    `${walletAddress}\n`,
    "Nonce:",
    `${nonce}`,
  ];
  const message = messageContent.join("\n");
  return message;
};

export const connectToWallet = async ({
  callback,
}: {
  callback?: () => void;
}) => {
  const { ethereum } = window;

  if (!ethereum) {
    return callback?.();
  }

  const networkMap = {
    POLYGON_MAINNET: {
      chainId: ethers.utils.hexValue(137), // '0x89'
      chainName: "Polygon Mainnet",
      nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
      rpcUrls: ["https://polygon-rpc.com"],
      blockExplorerUrls: ["https://www.polygonscan.com/"],
    },
    MUMBAI_TESTNET: {
      chainId: ethers.utils.hexValue(80001), // '0x13881'
      chainName: "Mumbai Testnet",
      nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
      rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
      blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
    },
  };

  const network =
    process.env.NEXT_PUBLIC_ENV === "production"
      ? networkMap.POLYGON_MAINNET
      : networkMap.MUMBAI_TESTNET;

  // Add network to users metamask if necessary
  await ethereum.request({
    method: "wallet_addEthereumChain",
    params: [network],
  });
  // Request to switch networks if not on the correct one
  await ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: network.chainId }],
  });

  const provider = new ethers.providers.Web3Provider(ethereum);
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();
  const walletAddress = await signer.getAddress();

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_BACKEND_URL}/auth/nonce`,
    {
      method: "POST",
      body: JSON.stringify({
        walletAddress,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const { nonce } = await response.json();
  const message = createMessage(walletAddress, nonce);
  const signature = await signer.signMessage(message);

  const verifyResponse = await fetch(
    `${process.env.NEXT_PUBLIC_BACKEND_URL}/auth/verify-signature`,
    {
      method: "POST",
      body: JSON.stringify({
        walletAddress,
        nonce,
        signature,
      }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const { token } = await verifyResponse.json();
  supabase.auth.setAuth(token);

  return walletAddress;
};
