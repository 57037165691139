import styled from "styled-components";
import { BaseButtonWithTransition } from "@/common/Buttons/shared";

interface IProps extends React.HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  width?: "full";
  type?: "submit" | "button";
  disabled?: boolean;
}

export default function SquareButton({
  children,
  width,
  type,
  disabled = false,
  ...rest
}: IProps) {
  return (
    <Button
      onClick={rest.onClick}
      width={width}
      type={type}
      disabled={disabled}
    >
      <Span>{children}</Span>
    </Button>
  );
}

interface ISquareButton {
  width?: "full";
}

export const Button = styled.button<ISquareButton>`
  ${BaseButtonWithTransition}
  color: white;
  padding: 10px 24px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  width: ${(props) => (props.width === "full" ? "100%" : "auto")};
  min-width: 150px;
  &::after {
    border-radius: 8px;
  }
  > * {
    position: relative;
    z-index: 3;
  }
`;

const Span = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;
